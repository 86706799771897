<!--
 * @Author: sopen
 * @Date: 2021-06-30 10:04:37
 * @LastEditTime: 2021-09-11 07:17:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/views/home/usinfo.vue
-->
<template>
  <v-app>
    <inc-header
      id="menu"
      :project="{ name: '监护人信息' }"
      :mobile="mobile"
      :menu="menu"
      :help="false"
    ></inc-header>
    <v-container app fluid>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-alert
            outlined
            type="info"
            prominent
            border="left"
            class="mt-4 mb-0"
          >
            用户允许增加多个监护（子女）信息，可以在身份切换里切换身份。
          </v-alert>
        </v-col>
        <v-col cols="12" md="6" offset-md="3">
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="addChild">
                <v-icon>mdi-plus</v-icon>
                添加子女信息
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="mt-4" v-for="(ts, tx) in child" :key="tx">
            <v-toolbar color="blue darken-1" dark elevation="1" class="my-2">
              <v-icon class="mr-4 text-h3">mdi-account-child-circle</v-icon>
              <v-toolbar-title>
                {{ ts["name"] }}<br />
                <div style="font-size: 14px; margin: 0">{{ ts["idcard"] }}</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                small
                class="mr-1"
                @click="
                  removeIdcard = ts['idcard'];
                  dialogRemove = true;
                "
              >
                <v-icon>mdi-delete-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>

            <v-list
              subheader
              v-for="(item, x) in ts.info"
              :key="x"
              class="pb-0"
            >
              <v-divider v-if="x > 0 ? true : false"></v-divider>
              <v-list-item class="mb-4">
                <v-list-item-title class="pt-5">
                  <span class="text-h6">{{ item.schoolname }}</span>
                  <br />
                  <v-chip-group class="my-2">
                    <v-chip
                      color="teal lighten-1"
                      dark
                      small
                      label
                      class="mr-1"
                    >
                      {{ $sopen.fun_className(item.class) }}
                    </v-chip>
                    <v-chip
                      v-if="
                        item.my == 'psid01' || item.my == 'psid02'
                          ? true
                          : false
                      "
                      elevation="0"
                      label
                      dark
                      small
                      color="indigo lighten-1"
                      class="ml-0"
                    >
                      监护人
                    </v-chip>
                  </v-chip-group>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="ml-2">
                <v-list-item-avatar>
                  <v-img
                    v-if="
                      item['psid00-name'] != undefined && item['psid00-face']
                        ? true
                        : false
                    "
                    :src="item['psid00-face']"
                  ></v-img>
                  <v-icon v-else class="text-h3" color="grey lighten-1">
                    mdi-account-circle-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  <v-chip class="ma-2" small color="success" outlined>
                    学生
                  </v-chip>
                  <span v-if="item['psid00'] > 0 ? true : false">
                    {{ item["psid00-name"] + "（ID:" + item["psid00"] + "）" }}
                  </span>
                  <v-chip
                    small
                    v-if="item['psid00'] < 1 ? true : false"
                    class="ma-2"
                  >
                    未关联
                  </v-chip>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="ml-2">
                <v-list-item-avatar>
                  <v-img
                    v-if="
                      item['psid01-name'] != undefined && item['psid01-face']
                        ? true
                        : false
                    "
                    :src="item['psid01-face']"
                  ></v-img>
                  <v-icon v-else class="text-h3" color="grey lighten-1">
                    mdi-account-circle-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  <v-chip class="ma-2" small color="warning" outlined>
                    监护
                  </v-chip>
                  <span
                    v-if="item['psid01'] && item['psid01'] > 0 ? true : false"
                  >
                    {{ item["psid01-name"] + "（ID:" + item["psid01"] + "）" }}
                  </span>
                  <v-chip
                    v-if="'psid01' == item.my ? true : false"
                    elevation="0"
                    fab
                    dark
                    small
                    color="green lighten-1"
                    class="pa-2"
                  >
                    我
                  </v-chip>
                  <v-chip
                    small
                    v-if="
                      !item['psid01'] || (item['psid01'] && item['psid01'] < 1)
                        ? true
                        : false
                    "
                    class="ma-2"
                  >
                    未关联
                  </v-chip>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="ml-2">
                <v-list-item-avatar>
                  <v-img
                    v-if="
                      item['psid02-name'] != undefined && item['psid02-face']
                        ? true
                        : false
                    "
                    :src="item['psid02-face']"
                  ></v-img>
                  <v-icon v-else class="text-h3" color="grey lighten-1">
                    mdi-account-circle-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  <v-chip class="ma-2" small color="warning" outlined>
                    监护
                  </v-chip>
                  <span
                    v-if="item['psid02'] && item['psid02'] > 0 ? true : false"
                  >
                    {{ item["psid02-name"] + "（ID:" + item["psid02"] + "）" }}
                  </span>
                  <v-chip
                    v-if="item['psid02'] == item.my ? true : false"
                    elevation="0"
                    fab
                    dark
                    small
                    color="green lighten-1"
                    class="pa-2"
                  >
                    我
                  </v-chip>
                  <v-chip
                    small
                    v-if="
                      !item['psid02'] || (item['psid02'] && item['psid02'] < 1)
                        ? true
                        : false
                    "
                    class="ma-2"
                  >
                    未关联
                  </v-chip>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="grey lighten-4 px-2 py-1 mt-4">
                <v-list-item-title align="right">
                  <v-btn
                    :color="item.my == undefined ? 'success' : 'warning'"
                    elevation="0"
                    :disabled="item.my == undefined && item.state < 1"
                    @click="userLink(item)"
                  >
                    <v-icon small class="mr-2">
                      {{ item.my == undefined ? "mdi-check" : "mdi-close" }}
                    </v-icon>
                    {{ item.my == undefined ? "关联身份" : "取消关联" }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="parentsDialog" persistent max-width="390">
        <v-card>
          <v-card-title class="text-h6">
            <v-icon class="red--text text-h4 mr-2"
              >mdi-alert-circle-outline</v-icon
            >
            改变身份提醒
            <v-spacer></v-spacer>
            <v-btn fab small text @click="parentsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-h7"
            >你的用户身份发生变化，这将影响你的相关功能，除非你确认需要进行更改。</v-card-text
          >
          <v-card-actions>
            <v-btn elevation="0" @click="parentsDialog = false"> 取消 </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="error darken-1"
              elevation="0"
              dark
              @click="parentsChange"
            >
              <v-icon class="mr-2">mdi-check</v-icon>
              确认变更身份
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="parentsAdd" persistent max-width="490">
        <v-card>
          <v-card-title class="text-h5">
            添加监护信息
            <v-spacer></v-spacer>
            <v-btn fab small text @click="parentsAdd = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-form ref="form" v-model="valid" class="ma-6">
            <v-text-field
              v-model="form.name"
              :rules="formRules.name"
              label="姓名"
              required
            >
            </v-text-field>
            <v-select
              :items="idcardItems"
              v-model="form.area"
              label="身份证区域"
              :rules="[(v) => !!v || '请选择身份证区域']"
            >
            </v-select>

            <v-text-field
              v-model="form.idcard"
              :rules="formRules.idcard"
              label="身份证号码"
              required
            >
            </v-text-field>
            <v-select
              :items="relation"
              v-model="form.relation"
              label="监护关系"
              :rules="[(v) => !!v || '请选择监护关系']"
            >
            </v-select>
          </v-form>
          <v-card-actions>
            <v-btn @click="parentsAdd = false"> 取消 </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success darken-1" dark @click="parentsPost">
              <v-icon class="mr-2">mdi-check</v-icon>
              确认提交
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogRemove"
        scrollable
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="text-h6 pl-3">
            <v-icon class="pr-4" color="error">mdi-alert</v-icon>
            系统提示
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text style="font-size: 18px; min-height: 100px" class="pa-5"
            >你确认要删除监护信息吗？</v-card-text
          >
          <v-card-actions>
            <v-btn elevation="0" @click="dialogRemove = false" class="mr-4">
              <v-icon class="pr-3">mdi-close</v-icon>
              取消
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn elevation="0" color="error" @click="removeChild">
              <v-icon class="pr-3">mdi-check</v-icon>
              确认退出
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        top
        color="red lighten-2"
        :multi-line="multiLine"
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            small
            fab
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
import incHeader from "../inc-header.vue";
export default {
  components: { incHeader },
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      menu: [
        {
          text: "添加子女信息",
          fun: "addChild()",
          icon: "mdi-plus",
        },
      ],
      offsetMd: 3,
      infouser: {
        idcard: "身份证号码",
        relation: "关系",
      },
      child: [],
      item: {},
      parentsDialog: false,
      parentsAdd: false,
      valid: true,
      form: {
        name: "",
        area: "中国大陆",
        idcard: "",
        relation: "",
      },
      formRules: {
        name: [
          (v) => !!v || "姓名不能为空",
          (v) => (v && v.length >= 2) || "姓名不能少于2个字符",
        ],
        idcard: [(v) => !!v || "身份证号码不能为空"],
      },
      idcardItems: ["中国大陆", "中国台湾", "中国香港", "中国澳门"],
      relation: ["父子（女）", "母子（女）", "其他"],
      multiLine: true,
      snackbar: false,
      snackbarText: "",

      removeIdcard: "",
      dialogRemove: false,
    };
  },
  mounted() {
    this.usinfo();
  },

  methods: {
    addChild() {
      this.parentsAdd = true;
    },
    usinfo() {
      let data = {
        module: "usinfo",
        title: "parents",
      };

      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        //console.log(res);
        that.child = res.data.usinfo.infouser;
        //console.log(that.child);
        //that.offsetMd = Object.keys(that.child).length > 1 ? 0 : 3;
        for (let x in that.child) {
          let info = [];
          for (let y in that.child[x]["info"]) {
            info.push(that.child[x]["info"][y]);
          }
          that.child[x]["info"] = info;
        }
      });
    },
    eachItem(x, ts) {
      switch (x) {
        case "relation":
          return ts == "father" ? "父亲" : ts == "mother" ? "母亲" : "其他";
        default:
          return ts;
      }
    },
    userLink(item) {
      this.item = item;
      if (item.my != undefined) {
        this.parentsDialog = true;
      } else {
        this.parentsChange();
      }
    },
    parentsChange() {
      let item = this.item;
      let data = {
        module: "usinfo",
        title: "linkParents",
        data: {
          id: item.id,
          idcard: item.idcard,
        },
      };

      let that = this;
      this.$sopen.requestUscenterApi(data).then(function () {
        ////console.log(res);
        //that.usinfo();
        that.parentsDialog = false;
        window.location.reload();
      });
    },
    parentsPost() {
      let state = this.$refs.form.validate();
      if (state) {
        let area = "cn";
        switch (this.form.area) {
          case "中国台湾":
            area = "tw";
            break;
          case "中国香港":
            area = "hk";
            break;
          case "中国澳门":
            area = "mo";
            break;
        }
        let relation = "";
        switch (this.form.relation) {
          case "父子（女）":
            relation = "father";
            break;
          case "母子（女）":
            relation = "mother";
            break;
          case "其他":
            relation = "order";
            break;
        }
        let data = {
          module: "user",
          title: "relation",
          data: {
            name: this.form.name,
            area: area,
            idcard: this.form.idcard,
            relation: relation,
          },
        };

        let that = this;
        this.$sopen.requestUscenterApi(data).then(function (res) {
          let redata = res.data.user;
          if (redata.errcode > 0) {
            that.snackbarText = redata.errmsg;
            that.snackbar = true;
          } else {
            that.parentsDialog = false;
          }
          that.usinfo();
          that.parentsAdd = false;
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    removeChild() {
      let data = {
        module: "user",
        title: "removeRelation",
        data: {
          idcard: this.removeIdcard,
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function () {
        that.usinfo();
        that.parentsChange();
        that.dialogRemove = false;
      });
    },
  },
  created() {},
  watch: {},
};
</script>